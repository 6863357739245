
import { defineComponent, computed, ref, watchEffect, Ref } from "vue";

import Button from "@/components/ButtonWave.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    Button,
  },

  props: {
    modelValue: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const store = useStore();

    const currentMinted = computed(() => store.state.mint.currentMinted);
    const supply = computed(() => store.state.mint.supply);
    const muted = ref(true);

    const audio: Ref<null | HTMLAudioElement> = ref(null);

    watchEffect(() => {
      if (audio.value) {
        if (muted.value) audio.value.pause();
        else if (!muted.value) audio.value.play();
      }

      store.commit("mint/changeMuted", muted.value);
    });

    const open = (link: string) => {
      window.open(link);
    };

    return {
      router,
      route,
      currentMinted,
      supply,
      store,
      muted,
      audio,
      open,
    };
  },
});
