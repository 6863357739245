<template>
  <button class="button">
    <span class="content">
      <slot></slot>
    </span>
  </button>
</template>

<style lang="scss" scoped>
button {
  height: 56px;
  padding: 0 30px;
  overflow: hidden;
  transition: transform 0.2s;
  position: relative;
  display: inline-block;
  animation: apperance 0.5s ease-in 0.15s;
  overflow: hidden;
  background: #b29955;
  color: #000000;
  font-weight: 700;
  font-size: 16px;
  line-height: 2;
  letter-spacing: 0.05em;
  text-transform: capitalize;
  text-transform: uppercase;
  border-radius: 50px;
  border: none;

  @media (max-width: 520px) {
    font-size: 12px;
  }

  .content {
    transition: opacity 0.3s, transform 0.3s;
    position: relative;
    display: block;
    box-sizing: border-box;
    animation: content 0.3s ease-in;

    @keyframes content {
      from {
        transform: translate(0, 0);
        opacity: 1;
      }

      50% {
        transform: translate(0, 100%);
        opacity: 0;
      }

      51% {
        transform: translate(0, -100%);
        opacity: 0;
      }

      to {
        transform: translate(0, 0%);
        opacity: 1;
      }
    }
  }

  &:hover {
    transform: scale(1.065);

    .content {
      animation: hover 0.3s ease-in;

      @keyframes hover {
        from {
          transform: translate(0, 0);
          opacity: 1;
        }

        50% {
          transform: translate(0, -100%);
          opacity: 0;
        }

        51% {
          transform: translate(0, 100%);
          opacity: 0;
        }

        to {
          transform: translate(0, 0%);
          opacity: 1;
        }
      }
    }
  }
}
</style>
