
import { defineComponent, onMounted, computed } from "vue";
import Navbar from "@/components/Navbar.vue";
import { useStore } from "vuex";
import { event } from "vue-gtag";
export default defineComponent({
  components: {
    Navbar,
  },
  setup() {
    const store = useStore();

    const muted = computed(() => store.state.mint.muted);

    onMounted(() => {
      store.dispatch("mint/initCollection");
      event("Enter the page");
    });

    return {
      muted,
    };
  },
});
