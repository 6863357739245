import { ActionTree, MutationTree } from "vuex";

import Web3 from "web3";
import { AbiItem } from "web3-utils";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";

type MintModuleState = {
  provider: any;
  token_abi: AbiItem[];
  account_address: string;
  contract_address: string;
  web3: Web3;
  collection: any;
  NFTPrice: number;
  limit: number;
  currentMinted: number;
  supply: number;
  currentStep: string;
  message: string;
  free: number;
  freeLimit: number;
  muted: boolean;
};

const provider = new Web3.providers.HttpProvider(
  "https://mainnet.infura.io/v3/9bfb6dd9222a4f0b9fbc6d9ca75bbc12"
);

const web3 = new Web3(provider);

const account = async (provider: any) => {
  const _res = await provider.send("eth_requestAccounts");
  return _res.result[0];
};

const mintModuleState: MintModuleState = {
  provider: provider,
  token_abi: [
    {
      "inputs": [],
      "name": "MAX_NFT_PER_TRAN",
      "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "MINT_PRICE",
      "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "maxSupply",
      "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{
        "internalType": "address",
        "name": "owner",
        "type": "address"
      }],
      "name": "mintedCount",
      "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [{
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{
        "internalType": "uint256",
        "name": "count",
        "type": "uint256"
      }],
      "name": "mint",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
  ],
  account_address: "",
  contract_address: "0xc770cb6bbca4d9676d28cfbed4b11176925255d6",
  web3: web3,
  collection: new web3.eth.Contract([]),
  NFTPrice: 0,
  limit: 0,
  currentMinted: 0,
  supply: 0,
  currentStep: "intro",
  message: "",
  free: 0,
  freeLimit: 0,
  muted: true,
};

const mintModuleMutations = <MutationTree<MintModuleState>>{
  changeProvider(state, newProvider: any) {
    state.provider = newProvider;
  },
  changeWeb3(state, newWeb3: Web3) {
    state.web3 = newWeb3;
  },
  changeCollection(state, newCollection: any) {
    state.collection = newCollection;
  },
  changeAccountAddress(state, newAddress: string) {
    state.account_address = newAddress;
  },
  changePrice(state, newPrice: number) {
    state.NFTPrice = newPrice;
  },
  changeLimit(state, newLimit: number) {
    state.limit = newLimit;
  },
  changeCurrentMinted(state, newMinted: number) {
    state.currentMinted = newMinted;
  },
  changeSupply(state, newSupply: number) {
    state.supply = newSupply;
  },
  changeCurrentStep(state, newStep: string) {
    state.currentStep = newStep;
  },
  changeMessage(state, newMessage: string) {
    state.message = newMessage;
  },
  changeFree(state, newFree: number) {
    state.free = newFree;
  },
  changeFreeLimit(state, newFreeLimit: number) {
    state.freeLimit = newFreeLimit;
  },
  changeMuted(state, newMuted: boolean) {
    state.muted = newMuted;
  },
};

const mintModuleActions = <ActionTree<MintModuleState, null>>{
  initCollection: async (context) => {
    context.commit(
      "changeWeb3",
      new Web3(context.state.provider || (window as any).ethereum)
    );

    context.commit(
      "changeCollection",
      new context.state.web3.eth.Contract(
        context.state.token_abi,
        context.state.contract_address
      )
    );

    context.state.collection.methods
      .MINT_PRICE()
      .call()
      .then((res: any) => {
        context.commit("changePrice", Number(res));
      });

    context.state.collection.methods
      .MAX_NFT_PER_TRAN()
      .call()
      .then((res: any) => {
        context.commit("changeLimit", Number(res));
      });

    // context.state.collection.methods
    //   .MAX_NFT_PER_TRAN()
    //   .call()
    //   .then((res: any) => {
        context.commit("changeFreeLimit", Number(0));
      // });

    context.commit(
      "changeCurrentMinted",
      Number(await context.state.collection.methods.totalSupply().call())
    );

    context.commit(
      "changeSupply",
      Number(await context.state.collection.methods.maxSupply().call())
    );
    context.commit(
      "changeFree",
      Number(0)
    );
  },
  metamaskConnect: async (context) => {
    context.commit("changeCurrentStep", "connecting");
    context.commit("changeProvider", await detectEthereumProvider());

    if (!context.state.provider) {
      alert("Please install MetaMask!");
      context.commit("changeCurrentStep", "connect");
    } else if (context.state.provider !== (window as any).ethereum) {
      console.error("Do you have multiple wallets installed?");
    }

    await context.state.provider
      .enable()
      .then(() => {
        context.commit("changeCurrentStep", "connected");
      })
      .catch(() => {
        context.commit("changeCurrentStep", "connect");
      });

    const accounts = await context.state.provider.enable();
    context.commit("changeAccountAddress", accounts[0]);

    context.dispatch("initCollection");
  },
  walletConnect: async (context) => {
    context.commit("changeCurrentStep", "connecting");
    context.commit(
      "changeProvider",
      new WalletConnectProvider({
        infuraId: "87f7a1aa936545089e166b719aa876d7",
      })
    );

    await context.state.provider
      .enable()
      .then(() => {
        context.commit("changeCurrentStep", "connected");
      })
      .catch(() => {
        context.commit("changeCurrentStep", "connect");
      });

    const accounts = await context.state.provider.enable();
    context.commit("changeAccountAddress", accounts[0]);

    context.dispatch("initCollection");
  },
  mint: async (context, NFTCount: number) => {
    context.commit("changeCurrentStep", "minting");

    const mintedByWallet = await context.state.collection.methods.mintedCount(context.state.account_address).call()

    try {
      await context.state.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
    } catch (e) {
      console.log(e);
    }

    const checkout =
      mintedByWallet < 1
        ? context.state.NFTPrice * (NFTCount - 1)
        : context.state.NFTPrice * NFTCount;

    await context.state.collection.methods
      .mint(NFTCount)
      .send({
        from: context.state.account_address,
        value: "" + checkout,
      })
      .then(() => {
        context.commit("changeMessage", "success");
      })
      .catch((e: any) => {
        context.commit("changeMessage", "failed");
      });
    context.commit("changeCurrentStep", "minted");

    context.dispatch("initCollection");
  },
};

const mintModule = {
  namespaced: true,

  state: mintModuleState,
  mutations: mintModuleMutations,
  actions: mintModuleActions,
};

export { mintModule };
