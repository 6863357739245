
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watchEffect,
  Ref,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";

import { event } from "vue-gtag";
import { useStore } from "vuex";

export default defineComponent({
  name: "HomeView",

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const activePage = ref(0);

    const isActive = ref(true);

    const sliderIndex = ref(0);

    const openModal = ref(false);
    const currentPhoto = ref("shirt");

    const ts = ref(0);

    const muted = ref(true);

    const audio: Ref<null | HTMLAudioElement> = ref(null);

    watchEffect(() => {
      if (audio.value) {
        if (muted.value) audio.value.pause();
        else if (!muted.value) audio.value.play();
      }

      store.commit("mint/changeMuted", muted.value);
    });

    const scrollHandler = (event: WheelEvent) => {
      if (isActive.value)
        if (event.deltaY < 0) {
          activePage.value == 0 ? (activePage.value = 4) : activePage.value--;
          isActive.value = false;
          openModal.value = false;
          setTimeout(() => (isActive.value = true), 1000);
        } else {
          activePage.value == 4 ? (activePage.value = 0) : activePage.value++;
          isActive.value = false;
          openModal.value = false;
          setTimeout(() => (isActive.value = true), 1000);
        }
    };

    const touchStartHandler = (event: TouchEvent) => {
      ts.value = event.touches[0].clientY;
    };

    const open = (photo: string) => {
      openModal.value = true;

      currentPhoto.value = photo;
    };

    const closeModal = (e: KeyboardEvent) => {
      if (e.key === "Escape") openModal.value = false;
    };

    watchEffect(() => {
      event("Visit screen - " + activePage.value);
    });

    const touchEndHandler = (event: TouchEvent) => {
      const te = event.changedTouches[0].clientY;
      if (isActive.value)
        if (ts.value > te + 5) {
          activePage.value == 4 ? (activePage.value = 0) : activePage.value++;
          isActive.value = false;
          setTimeout(() => (isActive.value = true), 1000);
          openModal.value = false;
        } else if (ts.value < te - 5) {
          activePage.value == 0 ? (activePage.value = 4) : activePage.value--;
          isActive.value = false;
          setTimeout(() => (isActive.value = true), 1000);
          openModal.value = false;
        }
    };

    const images_generated = ref(1);
    const images_generated_show = ref(false);
    const images_doubler = ref(2);
    const images_doubler_show = ref(true);

    setInterval(() => {
      images_generated_show.value = !images_generated_show.value;
      images_doubler_show.value = !images_doubler_show.value;
    }, 2000);

    setTimeout(() => {
      setInterval(() => {
        images_generated.value < 8
          ? (images_generated.value += 2)
          : (images_generated.value = 2);
      }, 4000);
    }, 1000);

    setTimeout(() => {
      setInterval(() => {
        images_doubler.value < 8
          ? (images_doubler.value += 2)
          : (images_doubler.value = 1);
      }, 4000);
    }, 3000);

    onMounted(() => {
      document.addEventListener("wheel", scrollHandler);

      document.addEventListener("keydown", closeModal);

      document.addEventListener("touchstart", touchStartHandler);
      document.addEventListener("touchend", touchEndHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("wheel", scrollHandler);

      document.removeEventListener("keydown", closeModal);

      document.removeEventListener("touchstart", touchStartHandler);
      document.removeEventListener("touchend", touchEndHandler);
    });

    return {
      route,
      activePage,
      sliderIndex,
      router,
      open,
      openModal,
      currentPhoto,
      muted,
      audio,
      images_generated,
      images_generated_show,
      images_doubler,
      images_doubler_show,
    };
  },
});
